/* Container holding the image and the text */

.container {
  position: relative;
}

/* Bottom left text */

.bottom-left-plan {
  position: absolute;
  bottom: 106px;
  left: 30px;
  font-size: smaller;
}

.bottom-left-name {
  position: absolute;
  bottom: 84px;
  left: 30px;
  font-size: smaller;
}

.bottom-left-idcard {
  position: absolute;
  bottom: 62px;
  left: 30px;
  font-size: smaller;
}

.bottom-left-appnum {
  position: absolute;
  bottom: 40px;
  left: 30px;
  font-size: smaller;
}

.bottom-left-covdate {
  position: absolute;
  bottom: 18px;
  left: 30px;
  font-size: smaller;
}

/* Top left text */

.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px) {
  .bottom-left-plan {
    display: none;
  }
  .bottom-left-name {
    position: absolute;
    bottom: 84px;
    left: 30px;
    font-size: smaller;
  }
  .bottom-left-idcard {
    position: absolute;
    bottom: 62px;
    left: 30px;
    font-size: smaller;
  }
  .bottom-left-appnum {
    position: absolute;
    bottom: 40px;
    left: 30px;
    font-size: smaller;
  }
  .bottom-left-covdate {
    position: absolute;
    bottom: 18px;
    left: 30px;
    font-size: smaller;
  }
}

@media screen and (max-width: 480px) {
  .bottom-left-plan {
    display: none;
  }
  .bottom-left-name {
    position: absolute;
    bottom: 50px;
    left: 30px;
    font-size: small;
  }
  .bottom-left-idcard {
    position: absolute;
    bottom: 35px;
    left: 30px;
    font-size: xx-small;
  }
  .bottom-left-appnum {
    position: absolute;
    bottom: 20px;
    left: 30px;
    font-size: xx-small;
  }
  .bottom-left-covdate {
    position: absolute;
    bottom: 5px;
    left: 30px;
    font-size: xx-small;
  }
}

@media screen and (max-width: 320px) {
  .bottom-left-plan {
    display: none;
  }
  .bottom-left-name {
    position: absolute;
    bottom: 38px;
    left: 30px;
    font-size: xx-small;
  }
  .bottom-left-idcard {
    position: absolute;
    bottom: 27px;
    left: 30px;
    font-size: xx-small;
  }
  .bottom-left-appnum {
    position: absolute;
    bottom: 14px;
    left: 30px;
    font-size: xx-small;
  }
  .bottom-left-covdate {
    position: absolute;
    bottom: 5px;
    left: 30px;
    font-size: xx-small;
  }
}

@media screen and (max-width: 280px) {
  .bottom-left-plan {
    display: none;
  }
  .bottom-left-name {
    position: absolute;
    bottom: 35px;
    left: 30px;
    font-size: xx-small;
  }
  .bottom-left-idcard {
    position: absolute;
    bottom: 25px;
    left: 30px;
    font-size: xx-small;
  }
  .bottom-left-appnum {
    position: absolute;
    bottom: 15px;
    left: 30px;
    font-size: xx-small;
  }
  .bottom-left-covdate {
    position: absolute;
    bottom: 5px;
    left: 30px;
    font-size: xx-small;
  }
  .covid-file-num {
    display: none;
  }
}