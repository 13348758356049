.bg-auth {
    padding: 50px 0;
    height: auto;
    font-family: 'Kanit', sans-serif;
}

.bg-circle-gradiant {
    background: radial-gradient(circle at 50% 50%, rgba(255, 153, 0, 0.1) 0%, rgba(255, 153, 0, 0.1) 33.333%, rgba(255, 153, 0, 1) 33.333%, rgba(255, 153, 0, 1) 66.666%, rgba(221, 131, 0, 1) 66.666%, rgba(221, 131, 0, 1) 99.999%);
}

.bg-color {
    background-color: rgba(255, 153, 0, 0.801);
}

.bg-overlay {
    background-color: rgba(248, 178, 47, 0.808);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
    border-color: #ff9800;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(221, 131, 0, 0.9);
    outline: 0 none;
}

.auth-button button {
    border-radius: 10px;
    background-color: #ff9800;
    border-color: #ff9800;
    font-size: 16px;
    color: #ffffff;
    line-height: 1.2;
    height: 50px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.auth-button button:hover, .auth-button button:focus {
    background-color: #fe7200;
    border-radius: 10px;
    color: #fff;
    border-color: #ff9800;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(221, 131, 0, 0.9);
}

.form-group .icons {
    position: absolute;
    top: 36px;
    left: 18px;
    height: 20px;
}

/* รายละเอียด Card */

.product-card {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product-card .title {
    font-size: 20px;
}

.product-card .step, .product-card .step-icon {
    opacity: 0.06;
}

.product-card a {
    font-size: 15px;
}

.product-card:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

/* table compare */

.panel-body {
    height: 600px;
  }
  
  /* table {
    border-top: 1px solid black;
  } */
  table thead tr {
    min-height: 5em;    /* Fixed Row Height */
  }
  
  /* Flex Table */
  table.flex-table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  table.flex-table thead,
  table.flex-table tbody {
    display: block;
  }
  table.flex-table thead {
    margin-right: 0px;
  }
  table.flex-table tbody {
    flex: 1;
    overflow-y: scroll;
  }
  table.flex-table tr {
    width: 100%;
    display: flex;
  }
  table.flex-table tr td,
  table.flex-table tr th {
    display: flex;
    flex: 1;
    align-items:center;
    justify-content:center;
  }