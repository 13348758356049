.bg-auth {
    padding: 50px 0;
    height: auto;
    font-family: 'Kanit', sans-serif;
}

@media (max-width: 767px) {
    .bg-auth {
        padding: 150px 0;
        height: auto;
    }
}

.container-auth{
    min-height: 400px;
    margin-top: 50px;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: #ff9800;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(221, 131, 0, 0.9);
    outline: 0 none;
}

.auth-button button {
    border-radius: 10px;
    background-color: #ff9800;
    border-color: #ff9800;
    font-size: 16px;
    color: #ffffff;
    line-height: 1.2;
    height: 50px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.auth-button button:hover, .auth-button button:focus {
    background-color: #fe7200;
    border-radius: 10px;
    color: #fff;
    border-color: #ff9800;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(221, 131, 0, 0.9);
}

.form-group .icons {
    position: absolute;
    top: 36px;
    left: 18px;
    height: 20px;
}